input[type=text] {
    font-family: "Montserrat" !important;
  }
  
  .ModalBodyOTP {
    font-family: "IBMPlexSansArabic", "Montserrat" !important;
    font-size: 18px !important;
  }

  .digitsParent {
    background-color: #e4e4e4;
    border-radius: 20px;
  }   
  div:has(> .otpInput) {
    max-width: 70px;
    min-width: 33px;
    margin: 0px;
    padding: 3px;
  }
  
  .errorInput {
    border: 3px solid #ec1c24 !important;
    transition: all 0.2s;
  }