@use "../../../vars/colors" as *;

.addPointsContent {
  min-height: 100vh;
  width: 100%;
  background-color: #ededed;
  h4{
    font-weight: 700;
  }
  .addPointsTitle{
    color: #808080;
  }
  .bgWhite {
    background-color: #ffffff;
    border-radius: 15px;
    min-height: 150px;
    .addPointsLabel{
      font-weight: 700!important;
      font-size: 1rem;
    }
    .bgGrey{
      background-color: #e4e4e4;
      border-radius: 10px!important;
      .addpointInput{
        background-color: white;
        border-radius: 10px;
        text-align: center;
        font-size: 0.8rem;
        font-weight: 600 !important;
        &:focus {
          box-shadow: none !important;
          outline: none;
          border: 1px solid gray;
          font-weight: 700!important;
          font-size: 0.9rem;
        }
      }
    }
    .receiptValueUnit {
      font-weight: 700;
      line-height: 40px;
    }
  }
  .addpointsBtnContent{
    min-height: 150px;
    .addPointsBtn{
      background-color: $color_main;
      border-radius: 20px;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 35px;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

}
