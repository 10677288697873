@use "../../../vars/colors" as *;

.loginContent {
  min-height: 100vh;
  width: 100%;
  background: rgb(191, 26, 22);
  background: linear-gradient(
    35deg,
    rgba(191, 26, 22, 1) 0%,
    rgba(246, 224, 223, 1) 48%,
    rgba(255, 255, 255, 1) 100%
  );

  h5 {
    font-weight: 800;
    font-size: 1.4rem;
  }
  .loginCol {
    min-height: calc(100vh - 200px);
    .loginInput {
      background-color: $color_background;
      border-radius: 9px;
      .loginInputIcon {
        background-color: $color_background !important;
        border: none !important;
        color: $color_secondary !important;
        border-radius: 15px 0px 0px 15px !important;
        & > svg {
          font-size: 1.2rem;
        }
      }
      .loginInputField {
        background-color: $color_background !important;
        border: none !important;
        color: whitesmoke !important;
        font-weight: 600 !important;
        font-size: 0.9rem !important;
        height: 40px !important;
        border-radius: 0px 15px 15px 0px !important;
        padding-left: 25px !important;
        &:focus {
          box-shadow: none !important;
          color: #f4f3f4 !important;
          font-size: 0.8rem;
          font-weight: 700;
        }
        &::placeholder {
          color: gainsboro;
          font-weight: 600;
          font-size: 0.8rem;
        }
      }
      .branchLabel {
        color: whitesmoke;
        font-size: 0.8rem;
        font-weight: 600;
      }
      .branchInput {
        min-width: 100%;
        text-align: center !important;
        background-color: white !important;
        border: 2px solid #808080;
        font-size: 0.8rem;
        font-weight: 600 !important;
        border-radius: 9px;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .forgetPassowrdContent {
      text-align: right;
      a {
        text-decoration: none;
        color: #808080 !important;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
    .loginBtn {
      background-color: $color_main;
      border-radius: 10px;
      font-weight: 700;
      font-size: 0.8rem;
      padding: 5px;
      min-width: 200px;
    }
  }
  .greenLogo {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
  .poweredLogoContent {
    img {
      width: 100%;
      max-width: 120px;
      height: auto;
    }
  }
}
