@use "../../../vars/colors" as *;

.changeContent {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../imgs/BG.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  h5 {
    font-weight: 800;
    font-size: 1.4rem;
  }
  .changeCol {
    min-height: calc(100vh - 200px);
    .changeInput {
      background-color: #88c599;
      border-radius: 15px;
      .changeInputIcon {
        background-color: #88c599 !important;
        border: none !important;
        color: #282828 !important;
        border-radius: 15px 0px 0px 15px !important;
        & > svg {
          font-size: 1.2rem;
        }
      }
      .changeInputField {
        background-color: #88c599 !important;
        border: none !important;
        color: whitesmoke !important;
        font-weight: 600 !important;
        font-size: 0.9rem !important;
        height: 40px !important;
        border-radius: 0px 15px 15px 0px !important;
        padding-left: 25px !important;
        &:focus {
          box-shadow: none !important;
          color: #f4f3f4 !important;
          font-size: 0.8rem;
          font-weight: 700;
        }
        &::placeholder {
          color: gainsboro;
          font-weight: 600;
          font-size: 0.8rem;
        }
      }
      .branchLabel {
        color: whitesmoke;
        font-size: 0.8rem;
        font-weight: 600;
      }
      .branchInput {
        min-width: 100%;
        text-align: center !important;
        background-color: white !important;
        border: 2px solid #808080;
        font-size: 0.8rem;
        font-weight: 600 !important;
        border-radius: 15px;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .forgetPassowrdContent {
      text-align: right;
      a {
        text-decoration: none;
        color: #808080 !important;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
    .changeBtn {
      background-color: $color_main;
      border-radius: 10px;
      font-weight: 700;
      font-size: 0.8rem;
      padding: 5px;
      min-width: 200px;
    }
  }
  .greenLogo {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
  .poweredLogoContent {
    img {
      width: 100%;
      max-width: 120px;
      height: auto;
    }
  }
}
