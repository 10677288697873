@use "../../../../vars/colors" as *;

.cashiersHeader {
  background: linear-gradient(
    90deg,
    rgba(45, 62, 52, 1) 55%,
    rgba(85, 172, 110, 1) 100%
  );
  color: #ffffff;
  font-style: italic;
  font-weight: 800 !important;
}
.cashiersBody {
  background-color: #dcdcdc;
  .addCashierBtn {
    background-color: $color_main;
    font-weight: 700 !important;
    &:hover {
      background-color: #38884f;
    }
  }
  .bgWhite {
    background-color: #f5f5f5 !important;
    border-radius: 10px;
    .branchName,
    .cashierName {
      font-weight: 700;
    }
    .branchStyle {
      min-width: 100%;
      background-color: #f5f5f5 !important;
      border: 2px solid #808080;
      font-size: 0.8rem;
      font-weight: 600 !important;
    }
    .dropdownMenu {
      font-size: 0.8rem !important;
      a {
        font-weight: 600 !important;
      }
    }
    .cashierElement {
      border: 2px solid #808080;
      border-radius: 10px;
      span {
        font-weight: 700;
        font-size: 0.8rem;
      }
    }
  }
}
